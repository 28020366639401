import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/Layout";
export const _frontmatter = {
  "title": "Help"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Registering for events cannot get any easier! Find all answers to questions about it here, or `}<a parentName="p" {...{
        "href": "./about#contact"
      }}>{`contact us`}</a>{`.`}</p>
    <p>{`Do you organize events? Then try it in 90 seconds, `}<a parentName="p" {...{
        "href": "./owned-events"
      }}>{`click here`}</a>{` to
create an event for testing, without any cost or committment. You can then`}</p>
    <ol>
      <li parentName="ol">{`Invite your followers to register for your event. You can test it yourself first.`}</li>
      <li parentName="ol">{`See who has registered.`}</li>
      <li parentName="ol">{`Message them, and manage their registration.`}</li>
    </ol>
    <h1>{`How to Register for an Event?`}</h1>
    <p>{`(TODO: not written yet)`}</p>
    <h1>{`How to Create my Own Event?`}</h1>
    <p>{`(TODO: not written yet)`}</p>
    <h1>{`How secure is My Data, and the Data of those that Register?`}</h1>
    <p>{`(TODO)`}</p>
    <h1 id="listing">How to get my event listed, for anyone to find?</h1>
    <p>{`Your event will be listed publicly, when`}</p>
    <ol>
      <li parentName="ol">{`You mark your event as public.`}</li>
      <li parentName="ol">{`We review and approve your event.`}</li>
    </ol>
    <p>{`We will approve your event usually within less than 8 hours. If we cannot
approve your event, then we will let you know why.`}</p>
    <p>{`If you don't make your event public, then we will not list it. You can still
share a link to your event through your own networks, even if the event is not
public.`}</p>
    <h1>{`Contact Us`}</h1>
    <p>{`(TODO: WhatsApp, and Telegram, and email?)`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      